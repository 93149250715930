html,
body {
  height: 100%;
}

.logo {
  margin: 7px 0px;
  float: left;
  height: 50px;
}

.ant-layout.layout {
  min-height: 100vh;
}

.site-layout .site-layout-background {
  background: #fff;
  min-height: 40vh;
  padding: 24px;
}
.ant-layout-content.site-layout {
  padding: 0 50px;
  margin-top: 64px;
}

.ant-btn-left-align {
  text-align: left !important;
}

@media (max-width: 576px) {
  .site-layout .site-layout-background {
    padding: 24px 15px;
  }
  .ant-layout-content.site-layout {
    padding: 0 10px;
  }
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

hr.noshade {
  background: black;
}

div.caseHeader,
.caseHeader div {
  padding: 0;
  margin: 0;
  height: normal;
  white-space: normal;
  display: inline-block;
  line-height: normal;
}

.centerVertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-logo {
  background: #fff !important;
  min-height: 150px;
  /* max-height: 225px; */
}

.ecfmg-small-descriptions .ant-descriptions-item-label {
  font-size: 10px;
  line-height: normal;
  padding-bottom: 3px;
}

.ecfmg-small-descriptions .ant-descriptions-item-content {
  line-height: normal;
  overflow-wrap: anywhere;
}

.ecfmg-small-descriptions .ant-descriptions-row > th {
  padding-bottom: 0px;
}

.ecfmg-medium-table .ant-table.ant-table-small {
  font-size: 12px;
}
.ecfmg-medium-table .ant-table.ant-table-small .ant-table-tbody > tr > td,
.ecfmg-medium-table .ant-table.ant-table-small .ant-table-tbody > tr > th {
  padding: 4px 4px;
}

.ecfmg-small-table .ant-table.ant-table-small {
  font-size: 10px;
}
.ecfmg-small-table .ant-table.ant-table-small .ant-table-tbody > tr > td,
.ecfmg-small-table .ant-table.ant-table-small .ant-table-tbody > tr > th {
  padding: 2px 2px;
}

/* for finance settings page */
.rule-list-row {
  cursor: pointer;
}

.space-align-block {
  margin: 8px 4px;
  border: 1px solid #40a9ff;
  padding: 4px;
  flex: none;
}

.clickRowStyl {
  background-color: #deeff5;
}

/* for pdf viewer  */

.customCanvas {
  min-width: 80vw;

  max-width: 80vw;

  overflow: auto;
}

.customPrevBtn {
  cursor: pointer;

  display: inline-block;

  margin: 0;

  width: auto;

  color: #fa5b35;

  background-color: #f3f3f3;

  border-color: #fa5b35;

  border-width: 1px;

  border-style: solid;

  border-radius: 6px;
}

.customNextBtn {
  cursor: pointer;

  display: inline-block;

  margin: 0;

  width: auto;

  color: #fa5b35;

  background-color: #f3f3f3;

  border-color: #333333;

  border-width: 1px;

  border-style: solid;

  border-radius: 6px;
}

.customResetBtn {
  cursor: pointer;

  display: inline-block;

  margin: 0;

  width: auto;

  color: #45fa35;

  background-color: #f3f3f3;

  border-color: #45fa35;

  border-width: 1px;

  border-style: solid;

  border-radius: 6px;
}

.customPages {
  font-size: medium;

  margin: 0.5rem;
}

.customWrapper {
  height: 40px;

  font-size: 24px;

  padding: 12px;

  padding-bottom: 0;

  background-color: #fff;
}

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}
